<template>
  <div>
    <div class="details-can-afford panel">
      <h2 class="details-can-afford-title">Can I afford it?</h2>
      <div class="details-can-afford-body">
        <div class="details-can-afford-body-image">
          <img
            loading="lazy"
            :src="
              car.thumbnail ? car.thumbnail :
                ['U', 'T'].includes( car.stock_number.split('')[0] ) 
                  ? '/static/images/tCComingSoon.webp'
                  : '/static/images/hComingSoon.webp'
            "
          />
        </div>

        <div class="details-can-afford-body-calc">
          <div class="details-can-afford-body-calc-options form-row">
            <div class="form-group col-12 col-md-6">
              <label for="credit-score-calc">Credit Score</label>
              <select
                class="selectpicker form-control"
                name="credit-score-calc"
                id="credit-score-calc"
              >
                <option value="Excellent">
                  Excellent (800+ Fico&#174; Score)
                </option>
                <option value="Very Good" v-on:click="calcFinance()">
                  Very Good (740-799 Fico&#174; Score)
                </option>
                <option value="Good">Good (670-739 Fico&#174; Score)</option>
                <option value="Fair">Fair (580-669 Fico&#174; Score)</option>
                <option value="Challenged">
                  Challenged (&lt; 580 Fico&#174; Score)
                </option>
              </select>
            </div>

            <div class="form-group col-12 col-sm-6 col-md-3">
              <label for="down-payment-calc">Down Payment</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  type="number"
                  id="down-payment-calc"
                  class="form-control"
                  v-model="finance.downPayment"
                  @change="calcDownpaymentOnChange($event)"
                />
              </div>
            </div>

            <div class="form-group col-12 col-sm-6 col-md-3 mb-3">
              <label for="months-payment-calc">Term</label>
              <select
                class="selectpicker form-control"
                name="months"
                id="months-payment-calc"
                v-model="detailsPage.finance.numOfMonths"
                @change="calcMonthlyOnChange($event)"
              >
                <option value="36">36 months</option>
                <option value="48">48 months</option>
                <option value="60">60 months</option>
                <option value="72" selected>72 months</option>
                <option value="84">84 months</option>
              </select>
            </div>
          </div>

          <h3 class="afford-subheading">
            Your Estimated Terms
            <sup>**</sup>
          </h3>

          <div
            class="details-can-afford-body-calc-estimate bottom-divider pb-3 row"
          >
            <div
              class="details-can-afford-body-calc-estimate-left col-12 col-md-6"
            >
              <div class="afford-lineItem">
                <span>
                  {{ car.year }}
                  {{ car.make }}
                  {{ car.model }}
                  {{ car.series }}
                </span>
                <span>${{ car.price | formatPrices }}*</span>
              </div>
              <div class="afford-lineItem">
                <span>Tax, title and fees (CA Est.)</span>
                <span
                  >+${{ finance.taxTitleFees | formatPrices }}</span
                >
              </div>
              <div class="afford-lineItem">
                <span>Minus Down Payment</span>
                <span
                  >-${{ finance.downPayment | formatPrices }}</span
                >
              </div>
              <div class="afford-lineItem">
                <span class="uppercase">
                  <b>Finance Amount</b>
                </span>
                <span>
                  <b>${{ finance.totalAmount | formatPrices }}</b>
                </span>
              </div>
            </div>

            <div
              class="details-can-afford-body-calc-estimate-right col-12 col-md-6"
            >
              <div
                class="details-can-afford-body-calc-estimate-right-monthley-payments estimate-callout"
              >
                <span class="estimate-number mr-md-2 mr-0">
                  ${{ finance.monthlyPayment | formatPrices }}
                </span>
                <span class="etimate-label">Monthly</span>
              </div>
              <div
                class="details-can-afford-body-calc-estimate-right-interest-rate estimate-callout"
              >
                <span class="estimate-number mr-md-2 mr-0"
                  >{{ getAPR() }}%</span
                >
                <span class="etimate-label">APR</span>
              </div>
            </div>
          </div>

          <div
            class="details-can-afford-body-calc-save-time pt-3 d-flex flex-column justify-content-between"
          >
            <h3 class="afford-subheading">Save Time In-Store</h3>

            <div class="row mb-3">
              <div class="linkHolster col-12 d-flex flex-column flex-sm-row">
                <a href="/get-pre-approved/" class="btn btn-primary"
                  >Get Pre-qualified</a
                >
                <a
                  href="/apply-for-financing/"
                  class="btn btn-tertiary ml-0 mt-3 ml-sm-3 mt-sm-0"
                  >Apply for Financing</a
                >
              </div>
            </div>

            <p class="mb-0 mt-auto disclaimerText">
              **Values displayed are estimates for illustrative purposes only
              and do not constitute a request for specific credit terms or an
              offer of credit. Pre-qualification application is to determine
              estimated credit terms on the vehicle you select and is subject to
              credit approval and availability. Tax, title, and tags vary by
              state and will be calculated at the time of purchase. APRs and
              terms used in estimates may not be applicable based on vehicle and
              state of purchase.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detailsPage : {
      type     : Object,
      required : true
    }
  },
  data : function () {
    return {
      finance : { ...this.detailsPage.finance  },
      car     : { ...this.detailsPage.data.car },
    }
  },
  mounted() {
    this.calcFinance();
    this.calcMonthlyPayment();
  },
  computed : {

    APR() {
      const { new : isNew } = this.car;
      const { interestRateUsed : usedRate, interestRateNew : newRate } = this.finance;

      return isNew ? newRate : usedRate;
    }
  },

  methods: {
    calcFinance: function () {
      const { price } = this.car;
      const { downPayment } = this.finance;
      this.finance.taxTitleFees =
        Math.round( price * 0.1 ) + 600;
      this.finance.totalAmount =
        price + this.finance.taxTitleFees - this.finance.downPayment;
    },
    calcMonthlyPayment: function () {
      const { totalAmount : financePrice, numOfMonths : numberMonths } = this.finance;
      
      this.finance.monthlyPayment = Math.round(
        self.calcPayment(financePrice, this.APR, numberMonths)
      );
    },
    calcPayment: function ( financePrice, rate, numberMonths ) {
      let P = financePrice; // Price being finance
      let r = rate / 12 / 100; // The monthly rate
      let n = numberMonths; // Number of months of loan
      let A = P * ( ( r * ( 1 + r ) ** n ) / ( ( 1 + r ) ** n - 1 ) ); // Monthly payments due

      return A;
    },
    // calculate VDP downpayment
    calcDownpaymentOnChange( e ) {
      this.calcFinance();
      this.calcMonthlyPayment();
    },

    // calculate monthly payment (VDP)
    calcMonthlyOnChange( e ) {
      this.calcMonthlyPayment();
    },
  },
  filters: {
    formatPrices: function ( number ) {
      if ( number != null ) {
        return number.toString().replace( /\B(?=(\d{3})+(?!\d))/g, "," );
      }
    },
  },
};
</script>
