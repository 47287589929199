var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { staticClass: "dropdown filterDropdown", attrs: { cols: "12" } },
        [
          _c(
            "div",
            {
              staticClass: "filter-header",
              attrs: { id: _vm.createFilterId({ header: true }) },
            },
            [
              _c(
                "h2",
                { staticClass: "mb-0" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle",
                          value: _vm.createFilterId(),
                          expression: "createFilterId()",
                        },
                      ],
                      staticClass: "btn btn-link btn-block text-left collapsed",
                      attrs: { id: _vm.createFilterId({ trigger: true }) },
                    },
                    [
                      [
                        _c("span", [_vm._v(_vm._s(_vm.title))]),
                        _vm._v(" "),
                        _c("b-icon", { attrs: { icon: "chevron-down" } }),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { id: _vm.createFilterId() } },
            _vm._l(_vm.attrs, function (attr, i) {
              return _c(
                "div",
                { key: `${attr}-${i}`, staticClass: "filter-body" },
                [
                  _c("ais-panel", {
                    scopedSlots: _vm._u(
                      [
                        attr.header
                          ? {
                              key: "header",
                              fn: function () {
                                return [
                                  _c("h3", { staticClass: "filter-title" }, [
                                    _vm._v(_vm._s(_vm.headerTitle(attr.name))),
                                  ]),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                        {
                          key: "default",
                          fn: function () {
                            return [
                              _c("ais-range-input", {
                                attrs: { attribute: attr.name },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({
                                        currentRefinement,
                                        range,
                                        refine,
                                      }) {
                                        return [
                                          _c("vue-slider", {
                                            attrs: {
                                              silent: "",
                                              tooltip: _vm.none,
                                              min: range.min,
                                              max: range.max,
                                              lazy: true,
                                              contained: true,
                                              value: _vm.toValue(
                                                currentRefinement,
                                                range
                                              ),
                                              marks: [range.min, range.max],
                                              railStyle: {
                                                backgroundColor: "#657E8F",
                                              },
                                              processStyle: {
                                                backgroundColor: "#0072CE",
                                              },
                                              labelStyle: {
                                                marginTop: "1.25em",
                                                color: "#0072CE",
                                                fontSize: ".7rem",
                                              },
                                            },
                                            on: {
                                              change: function ($event) {
                                                return refine({
                                                  min: $event[0],
                                                  max: $event[1],
                                                })
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "dot",
                                                  fn: function ({ focus }) {
                                                    return [
                                                      _c("div", {
                                                        class: {
                                                          "custom-dot": {
                                                            focus,
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }