<template>
	<b-row>
		<b-col cols="12" class="dropdown filterDropdown">
			<div class="filter-header" :id="createFilterId( { header : true } )">
				<h2 class="mb-0">
					<b-button
						class="btn btn-link btn-block text-left collapsed"
						:id="createFilterId( { trigger : true } )"
						v-b-toggle='createFilterId()'
					>
						<template>
							<span>{{ title }}</span>
							<b-icon icon="chevron-down"></b-icon>
						</template>
					</b-button>
				</h2>
			</div>
			<b-collapse :id="createFilterId()">
				<div v-for="( attr, i ) in attrs" class="filter-body" :key="`${attr}-${i}`">
					<ais-panel>
						<template v-if="attr.header" v-slot:header>
							<h3 class="filter-title">{{ headerTitle( attr.name ) }}</h3>
						</template>
						<template v-slot:default>
							<ais-refinement-list limit="1000" :attribute="attr.name" :sort-by="['name:asc']">
								<template v-slot:default="{ items, refine, createURL }">
									<ul class="ais-RefinementList-list trim-filter-list">
										<li
											:class="{'ais-refinementList-item' : true, 'isRefined' : item.isRefined, 'colorItem' : attr.colorItem }"
											v-for="item in items"
											:key="item.value"
											:disabled="!hasRefinements"
										>
											<a
												:class="{ 'aisRefinementList-link' : true, 'colorLink' : colorItem }"
												:href="createURL( item.value )"
												@click.prevent="refine(item.value)"
											>
												<span :class="['ais-RefinementList-label', attr.colorItem ? `${item.value.toLowerCase()}Label` : '']">
													<div v-if="attr.colorItem" class="swatch"></div>
													{{ item.label }}
												</span>
												<span class="ais-RefinementList-count">
														{{	item.count }}
												</span>
											</a>
										</li>
									</ul>
								</template>
							</ais-refinement-list>
						</template>
					</ais-panel>
				</div>
			</b-collapse>
		</b-col>
	</b-row>
</template>

<script>
export default {
	name: 'RefinementList',
	props: {
		attrs : {
			type: [{
				name : {
					type     : String,
					required : true
				},
				header : {
					type    : Boolean,
					default : false
				},
				colorItem : {
					type    : Boolean,
					default : false
				}
			}],
			required: true,
		},
		title : {
			type: String,
			required: true
		},
	},
	methods : {
		headerTitle : function( name ) {
			const titleArr = name.split( '_' )
			titleArr.forEach( function( word, index, attr ) {
				attr[ index ] = word.charAt( 0 ).toUpperCase() + word.slice( 1 );
			} )
			return titleArr.join( ' ' );
		},
		createFilterId : function( { header = false, trigger = false } = {} ) {
			if ( header ) return `${this.attrs.reduce( ( acc, curr ) => acc += curr.name.charAt( 0 ).toUpperCase() + curr.name.slice( 1 ), '')}FilterHeader`;
			if ( trigger ) return `${this.attrs.reduce( ( acc, curr ) => acc += curr.name.charAt( 0 ).toUpperCase() + curr.name.slice( 1 ), '')}FilterTrigger`;
			return `${this.attrs.reduce( ( acc, curr ) => acc += curr.name.charAt( 0 ).toUpperCase() + curr.name.slice( 1 ), '')}Filter`;
		},
	}
}
</script>