
export function filterByValue(array, string) {
    return array.filter(f => f.includes(string));
}

// filter helpers.
export function uniqueFilter(filterArr) {
    return filterArr.filter((elem, pos, arr) => {
        return arr.indexOf(elem) == pos && elem !== undefined && elem !== null;
    }).sort();
};

export function filterFeatures(features) {
    const vehicleFeatureWhitelist = [
        "3rd row seat", "adaptive cruise control", "android auto", "apple carplay", "backup camera",
        "blindspot monitor", "bluetooth", "fog lights", "hands-free liftgate", "heated seats",
        "interior accents", "keyless entry", "lane departure warning", "leather seats", "memory seats",
        "navigation system", "parking sensors", "power seats", "premium audio", "push start",
        "rain sensing wipers", "rear a/c", "satellite ready radio", "side-impact airbags",
        "sunroof / moonroof", "tow package", "wifi hotspot", "wireless phone charger",
    ];
    let validFeatures = [];
    // Iterate over primary list of "possible" features
    features.forEach((testFeature) => {
        // Iterate of secondary list of "allowed" features
        vehicleFeatureWhitelist.forEach((acceptableFeature) => {
            // Test if allowed feature is contained within string of possible feature (case insensitive)
            if (testFeature.toLowerCase().includes(acceptableFeature.toLowerCase())) {
                // Push to list of possible features that have passed
                validFeatures.push(testFeature);
            }
        });
    });
    return validFeatures;
}


// set year list against current year
export function yearList(range = 15) {
    const now = new Date().getUTCFullYear();
    const years = Array(now - (now - range)).fill('').map((v, idx) => now - idx);

    return years;
}

export const priceRangeList = [
    { value : 10000, text : "$10,000" },
    { value : 15000, text : "$15,000" },
    { value : 20000, text : "$20,000" },
    { value : 25000, text : "$25,000" },
    { value : 35000, text : "$35,000" },
    { value : 40000, text : "$40,000" },
    { value : 45000, text : "$45,000" },
    { value : 50000, text : "$50,000" },
    { value : 55000, text : "$55,000" },
    { value : 60000, text : "$60,000" },
    { value : 70000, text : "$70,000" },
    { value : 80000, text : "$80,000" },
];

export const mileageRangeList = [
    { value : 1000, text : "1,000" },
    { value : 2000, text : "2,000" },
    { value : 3000, text : "3,000" },
    { value : 4000, text : "4,000" },
    { value : 5000, text : "5,000" },
    { value : 10000, text : "10,000" },
    { value : 20000, text : "20,000" },
    { value : 30000, text : "30,000" },
    { value : 40000, text : "40,000" },
    { value : 50000, text : "50,000" },
    { value : 60000, text : "60,000" },
    { value : 70000, text : "70,000" },
    { value : 80000, text : "80,000" },
    { value : 90000, text : "90,000" },
    { value : 100000, text : "100,000" },
    { value : 150000, text : "150,000" },
];

export const cssColorNames = {
    "black"                       : "#000000",
    "silver"                      : "#C0C0C0",
    "gray"                        : "#808080",
    "grey"                        : "#808080",
    "white"                       : "#FFFFFF",
    "maroon"                      : "#800000",
    "red"                         : "#FF0000",
    "purple"                      : "#800080",
    "fuchsia"                     : "#FF00FF",
    "green"                       : "#008000",
    "lime"                        : "#00FF00",
    "olive"                       : "#808000",
    "yellow"                      : "#FFFF00",
    "navy"                        : "#000080",
    "blue"                        : "#0000FF",
    "teal"                        : "#008080",
    "aqua"                        : "#00FFFF",
    "darkblue"                    : "#00008B",
    "mediumblue"                  : "#0000CD",
    "darkgreen"                   : "#006400",
    "darkcyan"                    : "#008B8B",
    "deepskyblue"                 : "#00BFFF",
    "darkturquoise"               : "#00CED1",
    "mediumspringgreen"           : "#00FA9A",
    "springgreen"                 : "#00FF7F",
    "cyan"                        : "#00FFFF",
    "midnightblue"                : "#191970",
    "dodgerblue"                  : "#1E90FF",
    "lightseagreen"               : "#20B2AA",
    "forestgreen"                 : "#228B22",
    "seagreen"                    : "#2E8B57",
    "darkslategray"               : "#2F4F4F",
    "darkslategrey"               : "#2F4F4F",
    "limegreen"                   : "#32CD32",
    "mediumseagreen"              : "#3CB371",
    "turquoise"                   : "#40E0D0",
    "royalblue"                   : "#4169E1",
    "steelblue"                   : "#4682B4",
    "darkslateblue"               : "#483D8B",
    "mediumturquoise"             : "#48D1CC",
    "indigo"                      : "#4B0082",
    "darkolivegreen"              : "#556B2F",
    "cadetblue"                   : "#5F9EA0",
    "cornflowerblue"              : "#6495ED",
    "rebeccapurple"               : "#663399",
    "mediumaquamarine"            : "#66CDAA",
    "dimgray"                     : "#696969",
    "dimgrey"                     : "#696969",
    "slateblue"                   : "#6A5ACD",
    "olivedrab"                   : "#6B8E23",
    "slategray"                   : "#708090",
    "slategrey"                   : "#708090",
    "lightslategray"              : "#778899",
    "lightslategrey"              : "#778899",
    "mediumslateblue"             : "#7B68EE",
    "lawngreen"                   : "#7CFC00",
    "chartreuse"                  : "#7FFF00",
    "aquamarine"                  : "#7FFFD4",
    "skyblue"                     : "#87CEEB",
    "lightskyblue"                : "#87CEFA",
    "blueviolet"                  : "#8A2BE2",
    "darkred"                     : "#8B0000",
    "darkmagenta"                 : "#8B008B",
    "saddlebrown"                 : "#8B4513",
    "darkseagreen"                : "#8FBC8F",
    "lightgreen"                  : "#90EE90",
    "mediumpurple"                : "#9370DB",
    "darkviolet"                  : "#9400D3",
    "palegreen"                   : "#98FB98",
    "darkorchid"                  : "#9932CC",
    "yellowgreen"                 : "#9ACD32",
    "sienna"                      : "#A0522D",
    "brown"                       : "#A52A2A",
    "darkgray"                    : "#A9A9A9",
    "darkgrey"                    : "#A9A9A9",
    "lightblue"                   : "#ADD8E6",
    "greenyellow"                 : "#ADFF2F",
    "paleturquoise"               : "#AFEEEE",
    "lightsteelblue"              : "#B0C4DE",
    "powderblue"                  : "#B0E0E6",
    "firebrick"                   : "#B22222",
    "darkgoldenrod"               : "#B8860B",
    "mediumorchid"                : "#BA55D3",
    "rosybrown"                   : "#BC8F8F",
    "darkkhaki"                   : "#BDB76B",
    "mediumvioletred"             : "#C71585",
    "indianred"                   : "#CD5C5C",
    "peru"                        : "#CD853F",
    "chocolate"                   : "#D2691E",
    "tan"                         : "#D2B48C",
    "lightgray"                   : "#D3D3D3",
    "lightgrey"                   : "#D3D3D3",
    "thistle"                     : "#D8BFD8",
    "orchid"                      : "#DA70D6",
    "goldenrod"                   : "#DAA520",
    "palevioletred"               : "#DB7093",
    "crimson"                     : "#DC143C",
    "gainsboro"                   : "#DCDCDC",
    "plum"                        : "#DDA0DD",
    "burlywood"                   : "#DEB887",
    "lightcyan"                   : "#E0FFFF",
    "lavender"                    : "#E6E6FA",
    "darksalmon"                  : "#E9967A",
    "violet"                      : "#EE82EE",
    "palegoldenrod"               : "#EEE8AA",
    "lightcoral"                  : "#F08080",
    "khaki"                       : "#F0E68C",
    "aliceblue"                   : "#F0F8FF",
    "honeydew"                    : "#F0FFF0",
    "azure"                       : "#F0FFFF",
    "sandybrown"                  : "#F4A460",
    "wheat"                       : "#F5DEB3",
    "beige"                       : "#F5F5DC",
    "whitesmoke"                  : "#F5F5F5",
    "mintcream"                   : "#F5FFFA",
    "ghostwhite"                  : "#F8F8FF",
    "salmon"                      : "#FA8072",
    "antiquewhite"                : "#FAEBD7",
    "linen"                       : "#FAF0E6",
    "lightgoldenrodyellow"        : "#FAFAD2",
    "oldlace"                     : "#FDF5E6",
    "magenta"                     : "#FF00FF",
    "deeppink"                    : "#FF1493",
    "orangered"                   : "#FF4500",
    "tomato"                      : "#FF6347",
    "hotpink"                     : "#FF69B4",
    "coral"                       : "#FF7F50",
    "darkorange"                  : "#FF8C00",
    "lightsalmon"                 : "#FFA07A",
    "orange"                      : "#FFA500",
    "lightpink"                   : "#FFB6C1",
    "pink"                        : "#FFC0CB",
    "gold"                        : "#FFD700",
    "peachpuff"                   : "#FFDAB9",
    "navajowhite"                 : "#FFDEAD",
    "moccasin"                    : "#FFE4B5",
    "bisque"                      : "#FFE4C4",
    "mistyrose"                   : "#FFE4E1",
    "blanchedalmond"              : "#FFEBCD",
    "papayawhip"                  : "#FFEFD5",
    "lavenderblush"               : "#FFF0F5",
    "seashell"                    : "#FFF5EE",
    "cornsilk"                    : "#FFF8DC",
    "lemonchiffon"                : "#FFFACD",
    "floralwhite"                 : "#FFFAF0",
    "snow"                        : "#FFFAFA",
    "lightyellow"                 : "#FFFFE0",
    "ivory"                       : "#FFFFF0",
    "blackforestgreenpearlcoat"   : "#252f2d",
    "tungstenmetallic"            : "#b5ac9f",
    "deepoceanbluemetallic"       : "#003C5F",
    "graphitemetallic"            : "#4B4E53",
    "hermosablue"                 : "#9697c1",
    "aegeanbluemetallic"          : "#468fa2",
    "taffetawhite"                : "#F8F7F3",
    "premiumwhitepearl"           : "#F8F7F3",
    "whitediamondpearl"           : "#F2F2E8",
    "whiteorchidpearl"            : "#F2F2E8",
    "stormsilvermetallic"         : "#6E6F71",
    "alabastersilvermetallic"     : "#A4A4A4",
    "polishedmetalmetallic"       : "#363D43",
    "coolmistmetallic"            : "#91A0B7",
    "modernsteelmetallic"         : "#515860",
    "crystalblackpearl"           : "#1D1D1D",
    "mediterraneanbluepearl"      : "#053654",
    "northshorebluepearl"         : "#03387E",
    "vortexbluepearl"             : "#1D2B72",
    "balibluepearl"               : "#0E1831",
    "royalbluepearl"              : "#05165E",
    "frostedsilvermetallic"       : "#C5DBCF",
    "belizebluepearl"             : "#0E1E52",
    "dynobluepearl"               : "#083064",
    "dynobluepearlii"             : "#083064",
    "twilightbluemetallic"        : "#52657D",
    "celestialbluemetallic"       : "#6B89A3",
    "blueraspberrymetallic"       : "#239DAB",
    "obsidianbluepearl"           : "#06244C",
    "opalsagemetallic"            : "#566965",
    "greenopalmetallic"           : "#596365",
    "hematitemetallic"            : "#383D43",
    "crimsonpearl"                : "#6E0C19",
    "crimsonpearl"                : "#6E0C19",
    "sanmarinored"                : "#D70602",
    "rallyered"                   : "#F40500",
    "milanored"                   : "#7D0312",
    "darkcherrypearlii"           : "#4B1026",
    "basqueredpearlii"            : "#3C1818",
    "sunburstorangepearl"         : "#953407",
    "orangeburstmetallic"         : "#CE4300",
    "darkambermetallic"           : "#50312C",
    "urbantitaniummetallic"       : "#6C6C6C",
    "smokytopazmetallic"          : "#403A3A",
    "mochametallic"               : "#756653",
    "trufflepearl"                : "#423023",
    "crystalblackpearl"           : "#1D1D1D",
    "ravenblack"                  : "#000000",
    "sonicgraypearl"              : "#F8F7F3",
    "urbangraypearl"              : "#696661",
    "meteoritegraymetallic"       : "#696661",
    "modernsteelmetallic"         : "#515860",
    "pacificpewtermetallic"       : "#696661",
    "milano"                      : "#7D0312",
    "milanored"                   : "#7D0312",
    "rallye"                      : "#F40500",
    "rallyered"                   : "#F40500",
    "scarlet"                     : "#F42706",
    "scarletred"                  : "#F42706",
    "scarletredmetallic"          : "#F42706",
    "radiantredmetallic"          : "#F42706",
    "radiantredmetallicII"        : "#F42706",
    "sanmarinored"                : "#D70602",
    "lunarsilvermetallic"         : "#C5DBCF",
    "mercurysilvermetallic"       : "#C5DBCF",
    "morningmistmetallic"         : "#C5DBCF",
    "diffusedskypearl"            : "#C5DBCF",
    "solarsilvermetallic"         : "#C5DBCF",
    "platinumwhitepearl"          : "#F8F7F3",
    "championshipwhite"           : "#F8F7F3",
    "snowfallpearl"               : "#F8F7F3",
    "canyonriverblue"             : "#0E1E52",
    "aegeanbluemetallic"          : "#468fa2",
    "boostbluepearl"              : "#0E1E52",
    "obsidianbluepearl"           : "#06244C",
    "stillnightpearl"             : "#06244C",
    "smokeymauvepearl"            : "#423023",
    "blazingorangepearl"          : "#FF6501",
    "nordicforestpearl"           : "#423023",
    "northshorepearl"             : "#03387E",
};

export const mainMenuTheme = {
    earlySpring : {
        name               : 'earlySpring',
        background         : 'linear-gradient(89.97deg, #FFFFFF 66.59%, rgba(255, 255, 255, 0.63) 80.28%), url("/static/images/menu-images/early-spring/banner-image.webp")',
        logoBackground     : 'linear-gradient(90deg, rgba(0, 99, 179, 0.97) 25.72%, rgba(13, 79, 132, 0) 78.72%), url("/static/images/menu-images/early-spring/logo-image.webp")',
        color              : 'black',
        backgroundPosition : 'top 39% right'
    },
    fall : {
        name                   : 'fall',
        background             : 'linear-gradient(89.97deg, #FFFFFF 49.74%, rgba(255, 255, 255, 0.17) 97.41%), url("/static/images/menu-images/fall/banner-image.webp")',
        logoBackground         : 'linear-gradient(90deg, rgba(0, 99, 179, 0.97) 25.72%, rgba(13, 79, 132, 0) 78.72%), url("/static/images/menu-images/fall/logo-image.webp")',
        color                  : 'black',
        logoBackgroundPosition : 'top 45% right',
        backgroundPosition     : 'top 10% right'
    },
    flag : {
        name                   : 'flag',
        background             : 'linear-gradient(89.97deg, #FFFFFF 49.74%, rgba(255, 255, 255, 0.17) 97.41%), url("/static/images/menu-images/flag/logo-image.webp")',
        logoBackground         : 'linear-gradient(90deg, rgba(0, 99, 179, 0.97) 25.72%, rgba(13, 79, 132, 0) 78.72%), url("/static/images/menu-images/flag/logo-image.webp")',
        color                  : 'black',
        logoBackgroundPosition : '',
        backgroundPosition     : 'top 39% right'
    },
    halloween : {
        name                   : 'halloween',
        background             : 'linear-gradient(89.97deg, #1F1F1F 61.94%, rgba(0, 0, 0, 0) 74.56%), url("/static/images/menu-images/halloween/banner-image.webp")',
        logoBackground         : 'linear-gradient(90deg, rgba(0, 99, 179, 0.97) 25.72%, rgba(13, 79, 132, 0) 78.72%), url("/static/images/menu-images/halloween/logo-image.webp")',
        color                  : 'white',
        logoBackgroundPosition : 'top 30% right',
        backgroundPosition     : 'top 95% right'
    },
    newYears : {
        name                   : 'newYears',
        background             : 'linear-gradient(89.97deg, #2D2D2D 47.39%, #161616 68.93%, rgba(0, 0, 0, 0.4) 74.56%), url("/static/images/menu-images/new-years/banner-image.webp")',
        logoBackground         : 'linear-gradient(90deg, rgba(0, 99, 179, 0.97) 25.72%, rgba(13, 79, 132, 0) 78.72%), url("/static/images/menu-images/new-years/logo-image.webp")',
        color                  : 'white',
        logoBackgroundPosition : 'top 50% right',
        backgroundPosition     : 'top 25% right'
    },
    presidents : {
        name                   : 'presidents',
        background             : 'linear-gradient(89.97deg, #FFFFFF 49.74%, rgba(255, 255, 255, 0.17) 97.41%), url("/static/images/menu-images/presidents/banner-image.webp")',
        logoBackground         : 'linear-gradient(90deg, rgba(0, 99, 179, 0.97) 25.72%, rgba(13, 79, 132, 0) 78.72%), url("/static/images/menu-images/presidents/logo-image.webp")',
        color                  : 'black',
        logoBackgroundPosition : 'top 50% right',
        backgroundPosition     : 'top 39% right',
    },
    spring : {
        name                   : 'spring',
        background             : 'linear-gradient(89.97deg, #FFFFFF 49.74%, rgba(255, 255, 255, 0.17) 97.41%), url("/static/images/menu-images/spring/banner-image.webp")',
        logoBackground         : 'linear-gradient(90deg, rgba(0, 99, 179, 0.97) 25.72%, rgba(13, 79, 132, 0) 78.72%), url("/static/images/menu-images/spring/logo-image.webp")',
        color                  : 'black',
        logoBackgroundPosition : 'top 50% right',
        backgroundPosition     : 'top 39% right',
    },
    stPatricks : {
        name                   : 'stPatricks',
        background             : 'linear-gradient(89.97deg, #FFFFFF 49.74%, rgba(255, 255, 255, 0.17) 97.41%), url("/static/images/menu-images/st-patricks/banner-image.webp")',
        logoBackground         : 'linear-gradient(90deg, rgba(0, 99, 179, 0.97) 25.72%, rgba(13, 79, 132, 0) 78.72%), url("/static/images/menu-images/st-patricks/logo-image.webp")',
        color                  : 'black',
        logoBackgroundPosition : 'top 50% right',
        backgroundPosition     : 'top 65% right',
    },
    valentines : {
        name                   : 'valentines',
        background             : 'linear-gradient(89.97deg, #FFFFFF 49.74%, rgba(255, 255, 255, 0.17) 97.41%), url("/static/images/menu-images/valentines/banner-image.webp")',
        logoBackground         : 'linear-gradient(90deg, rgba(0, 99, 179, 0.97) 25.72%, rgba(13, 79, 132, 0) 78.72%), url("/static/images/menu-images/valentines/logo-image.webp")',
        color                  : 'black',
        logoBackgroundPosition : 'top 90% right',
        backgroundPosition     : 'top 75% right',
    },
    water : {
        name                   : 'water',
        background             : 'linear-gradient(89.97deg, #FFFFFF 49.74%, rgba(255, 255, 255, 0.17) 97.41%), url("/static/images/menu-images/water/banner-image.webp")',
        logoBackground         : 'linear-gradient(90deg, rgba(0, 99, 179, 0.97) 25.72%, rgba(13, 79, 132, 0) 78.72%), url("/static/images/menu-images/water/logo-image.webp")',
        color                  : 'black',
        logoBackgroundPosition : 'top 30% right',
        backgroundPosition     : 'top 66% right',
    },
    winter : {
        name                   : 'winter',
        background             : 'linear-gradient(89.97deg, #FFFFFF 49.74%, rgba(255, 255, 255, 0.17) 97.41%), url("/static/images/menu-images/winter/banner-image.webp")',
        logoBackground         : 'linear-gradient(90deg, rgba(0, 99, 179, 0.97) 25.72%, rgba(0, 0, 0, 0) 78.72%), url("/static/images/menu-images/winter/logo-image.webp")',
        color                  : 'black',
        logoBackgroundPosition : 'top 45% right',
        backgroundPosition     : 'top 40% right',
    },
    winterHoliday : {
        name                   : 'winterHoliday',
        background             : 'linear-gradient(89.97deg, #FFFFFF 49.74%, rgba(255, 255, 255, 0.17) 97.41%), url("/static/images/menu-images/winter-holiday/banner-image.webp")',
        logoBackground         : 'linear-gradient(90deg, rgba(0, 99, 179, 0.97) 0%, rgba(0, 0, 0, 0) 78.72%), url("/static/images/menu-images/winter-holiday/logo-image.webp")',
        color                  : 'black',
        logoBackgroundPosition : 'top 45% right',
        backgroundPosition     : 'top 50% right',
    },
}