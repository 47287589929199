import { mainMenuTheme } from "./helpers";

const isLeapYear = () => {
	const year = new Date().getFullYear();
	return year % 100 === 0 ? year % 400 === 0 : year % 4 === 0;
};

const januaryFirst = 1;
const januaryEighth = 8;
const februaryFirst = 32;
const februaryFifteenth = 46;
const endOfFebruary = isLeapYear() ? 60 : 59;
const marchFirst = isLeapYear() ? 61 : 60;
const marchSeventh = isLeapYear() ? 67 : 66;
const marchTwentieth = isLeapYear() ? 80 : 79;
const mayFifteenth = isLeapYear() ? 136 : 135;
const juneFirst = isLeapYear() ? 153 : 152;
const julyFirst = isLeapYear() ? 183 : 182;
const julyEighth = isLeapYear() ? 190 : 189;
const augustTwentyNinth = isLeapYear() ? 242 : 241;
const septemberEleventh = isLeapYear() ? 255 : 254;
const septemberTwelfth = isLeapYear() ? 256 : 255;
const septemberTwentyThird = isLeapYear() ? 267 : 266;
const octoberTenth = isLeapYear() ? 284 : 283;
const novemberFirst = isLeapYear() ? 306 : 305;
const novemberTwelfth = isLeapYear() ? 317 : 316;
const decemberFirst = isLeapYear() ? 336 : 335;
const decemberTwentyFirst = isLeapYear() ? 356 : 355;
const decemberTwentySixth = isLeapYear() ? 361 : 360;

// function that will return a range of days
const createRange = (start, end) => {
	const range = [];
	for (let i = start; i <= end; i++) {
		range.push(i);
	}
	return range;
};

const dateMap = {
	earlySpring : createRange(marchFirst, marchSeventh - 1),
	fall        : [...createRange( septemberTwentyThird, octoberTenth - 1 ), ...createRange( novemberTwelfth, decemberFirst - 1 )],
	flag        : [
		...createRange( mayFifteenth, juneFirst - 1 ),
		...createRange( augustTwentyNinth, septemberEleventh ),
		...createRange( novemberFirst, novemberTwelfth - 1 )
	],
	halloween  : createRange( octoberTenth, novemberFirst - 1 ),
	newYears   : [
		...createRange( januaryFirst, januaryEighth - 1 ),
		...createRange( decemberTwentySixth, januaryFirst - 1 ),
		...createRange( julyFirst, julyEighth - 1 ),
	],
	presidents : createRange( februaryFifteenth, endOfFebruary ),
	spring     : createRange( marchTwentieth, mayFifteenth - 1 ),
	stPatricks : createRange( marchSeventh, marchTwentieth - 1 ),
	valentines : createRange( februaryFirst, februaryFifteenth - 1 ),
	water      : [
		...createRange( juneFirst, julyFirst - 1 ),
		...createRange( julyEighth, augustTwentyNinth - 1 ),
		...createRange( septemberTwelfth, septemberTwentyThird - 1 )
	],
	winter        : [...createRange( decemberFirst, decemberTwentyFirst - 1 ), ...createRange( januaryEighth, februaryFirst - 1 )],
	winterHoliday : createRange( decemberTwentyFirst, decemberTwentySixth - 1 ),
};

const getTheme = (day) => {
	const theme = Object.keys(dateMap).find((theme) => dateMap[theme].includes(day));
	return mainMenuTheme[theme] || { background: "linear-gradient(180deg, #ffffff 0%, #ffffff 100%)", logoBackground: "linear-gradient(180deg, #0173CE 0%, #005BA4 100%);", color: "black" };
}

const dayOfYear = () => {
	const now = new Date();
	const start = new Date( now.getFullYear(), 0, 0 );
	// this takes into account daylight savings time
	const diff = ( now - start ) + ( ( start.getTimezoneOffset() - now.getTimezoneOffset() ) * 60 * 1000 );
	const oneDay = 1000 * 60 * 60 * 24;
	const day = Math.floor( diff / oneDay );

	return day;
};

export const themeObj = getTheme( dayOfYear() );

/*
? This is the code that changes the theme on click of the clock icon
export const changeTheme = ( theme ) => {
	const number = Object.keys( dateMap ).indexOf( theme ) === Object.keys( dateMap ).length - 1 ? 0 : Object.keys( dateMap ).indexOf( theme ) + 1;
	const newTheme = Object.keys( dateMap )[ number ];
	const themeObj = mainMenuTheme[ newTheme ];
	return themeObj;
}
*/