

/* OPEN NOW/CLOSE CODE - ON DOM READY */
document.addEventListener("DOMContentLoaded", function () {

    // Get current date (UTC day & UTC hours to account
    // for time zone differences.
    let d = new Date()
    let day = d.getUTCDay()
    let hour = d.getUTCHours()

    // default open to false
    // default start time to 17 UTC (9am PST)
    // default close time to 4 UTC + 1 day (24 hours) or 28 (8pm PST)
    let open = false
    let startime = 17
    let endtime = 28

    // check if is a certain day PST based on UTC day (x)
    function isDay(x) {
        // next day is current UTC day + 1 unless
        // current UTC is 6... then it is 0
        let nextDay = x + 1
        if (day == 6) {
            nextDay = 0
        }
        // if PST day = UTC day & time is between 8 & 23 or
        // if next PST day = UTC day and time is 7 or earlier
        // then it is that PST day
        return day == (x && hour >= 8 && hour <= 23) || (nextDay && hour <= 7)
    }

    // If today is Saturday, set end time to 7pm pst ( UTC 3 + 1 day = 27)
    if (isDay(6)) { endtime = 27 }

    // If today is Sunday, set start time to 10am PST (UTC 18)
    // and set end time to 6pm PST (UTC 2 + 1 day = 26)
    if (isDay(0)) {
        startime = 18
        endtime = 26
    }

    // If hour is 7 or before, add one day (24 hours) to hour
    if (hour <= 7) {
        hour += 24
    }

    // If hour is between start time & end time, set open to true
    if (hour >= startime && hour < endtime) {
        open = true
    }

    // Set text in Open Now/Closed button depending if open flag is true or false
    let openclose = "Closed"
    if (open) {
        openclose = "Open Now"
    }

    if (document.querySelector(".detect-open")) {
        document.querySelector(".detect-open").textContent = openclose
    }


    /** 
     * scroll to functionality for mobile buttons 
     * 
     * usage example: 
     * <a href="#" onclick="smoothScroll(document.getElementById('financing-specials'))">Lol I'm a button</a> 
    **/

    window.smoothScroll = function (target) {
        var scrollContainer = target;
        do { //find scroll container
            scrollContainer = scrollContainer.parentNode;
            if (!scrollContainer) return;
            scrollContainer.scrollTop += 1;
        } while (scrollContainer.scrollTop == 0);

        var targetY = 0;
        do { //find the top of target relatively to the container
            if (target == scrollContainer) break;
            targetY += target.offsetTop;
        } while (target = target.offsetParent);

        scroll = function (c, a, b, i) {
            i++; if (i > 30) return;
            c.scrollTop = a + (b - a) / 30 * i;
            setTimeout(function () { scroll(c, a, b, i); }, 20);
        }
        // start scrolling
        scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0);
    };


    // credit report form helpers

    $('input[name="ssn"]').keyup(function () {
        var self = this;
        var val = self.value.replace(/\D/g, '');
        val = val.replace(/^(\d{3})/, '$1-');
        val = val.replace(/-(\d{2})/, '-$1-');
        val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
        self.value = val;
    });

    $('#id_phone').keyup(function () {
        var self = this;
        var val = self.value.replace(/\D/g, '');
        val = val.replace(/^(\d{3})/, '$1-');
        val = val.replace(/-(\d{3})/, '-$1-');
        val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
        self.value = val;
    });

    $('#id_cell_phone').keyup(function () {
        var self = this;
        var val = self.value.replace(/\D/g, '');
        val = val.replace(/^(\d{3})/, '$1-');
        val = val.replace(/-(\d{3})/, '-$1-');
        val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
        self.value = val;
    });

    $('#id_work_phone').keyup(function () {
        var self = this;
        var val = self.value.replace(/\D/g, '');
        val = val.replace(/^(\d{3})/, '$1-');
        val = val.replace(/-(\d{3})/, '-$1-');
        val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
        self.value = val;
    })

    $("[data-fancybox]").fancybox({
        iframe : {
            css : {
                height : '800px'
            }
        }
    });
});

