<template>
  <div class="vdp-related-cars-carousel carousel-container carousel">
    <swiper-container
      class="related-carousel"
      :slides-per-view="related_cars.length < 3 ? related_cars.length : slidesPerView"
      :space-between="16"
      :centered-slides="related_cars.length < 3 ? related_cars.length % 2 !== 0 : slidesPerView % 2 !== 0"
      :loop="related_cars.length > 3"
      :initial-slide="related_cars.length < 3 ? related_cars.length % 2 !== 0 ? 1 : 0 : slidesPerView % 2 !== 0 ? 1 : 0"
      :navigation="related_cars.length > 3"
    >
      <swiper-slide
        class="vehicle-card-wrapper"
        v-for="car in related_cars"
        :key="car.id"
      >
        <new-vehicle-card
          :car="car"
        ></new-vehicle-card>
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<script>
export default {
  data: () => ( {
    windowWidth: window.innerWidth,
  } ),
  props: {
    related_cars: {
      type: Array,
      required: true,
    },
  },
  name: "related-vehicles",
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  computed: {
    slidesPerView() {
      if ( this.windowWidth < 768 ) return 1;
      else if ( this.windowWidth < 992 ) return 2;
      else return 3;
    },
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>
