<template>
	<b-row>
		<b-col cols="12" class="dropdown filterDropdown">
			<div class="filter-header" :id="createFilterId( { header : true } )">
				<h2 class="mb-0">
					<b-button
						class="btn btn-link btn-block text-left collapsed"
						:id="createFilterId( { trigger : true } )"
						v-b-toggle="createFilterId()"
					>
						<template>
							<span>{{ title }}</span>
							<b-icon icon="chevron-down"></b-icon>
						</template>
					</b-button>
				</h2>
			</div>
			<b-collapse :id="createFilterId()">
				<div v-for="( attr, i ) in attrs" class="filter-body" :key="`${attr}-${i}`">
					<ais-panel>
						<template v-if="attr.header" v-slot:header>
							<h3 class="filter-title">{{ headerTitle( attr.name ) }}</h3>
						</template>
						<template v-slot:default>
							<ais-range-input :attribute="attr.name">
								<template v-slot:default="{ currentRefinement, range, refine }">
									<vue-slider
										silent
										:tooltip="none"
										:min="range.min"
										:max="range.max"
										:lazy="true"
										:contained="true"
										:value="toValue( currentRefinement, range )"
										:marks="[range.min, range.max]"
										:railStyle="{ backgroundColor: '#657E8F' }"
										:processStyle="{ backgroundColor: '#0072CE' }"
										:labelStyle="{
											marginTop: '1.25em',
											color: '#0072CE',
											fontSize: '.7rem',
										}"
										@change="refine( { min: $event[0], max: $event[1] } )"
									>
										<template v-slot:dot="{ focus }">
											<div :class="{ 'custom-dot' : { focus } }"></div>
										</template>
									</vue-slider>
								</template>
							</ais-range-input>
						</template>
					</ais-panel>
				</div>
			</b-collapse>
		</b-col>
	</b-row>
</template>

<script>
import VueSlider from 'vue-slider-component';

export default {
	name  : 'VueSliderComp',
	props : {
		attrs : {
			type: [{
				name: {
					type     : String,
					required : true
				},
				header: {
					type    : Boolean,
					default : false
				},
			}],
			required: true,
		},
		title : {
			type: String,
			required: true
		},
	},
	methods : {
		headerTitle : function( name ) {
			if ( name == 'highway_mpg' ) return 'MPG (Hwy)';
			if ( name == 'city_mpg' ) return 'MPG (City)';
			const titleArr = name.split( '_' )
			titleArr.forEach( function( word, index, attr ) {
				attr[ index ] = word.charAt( 0 ).toUpperCase() + word.slice( 1 );
			} )
			return titleArr.join( ' ' );
		},
		createFilterId : function( { header = false, trigger = false } = {} ) {
			if ( header ) return `${this.attrs.reduce( ( acc, curr ) => acc += curr.name.charAt( 0 ).toUpperCase() + curr.name.slice( 1 ), '')}FilterHeader`;
			if ( trigger ) return `${this.attrs.reduce( ( acc, curr ) => acc += curr.name.charAt( 0 ).toUpperCase() + curr.name.slice( 1 ), '')}FilterTrigger`;
			return `${this.attrs.reduce( ( acc, curr ) => acc += curr.name.charAt( 0 ).toUpperCase() + curr.name.slice( 1 ), '')}Filter`;
		},
		toValue : ( value, range ) => {
			return [
				typeof value.min === 'number' ? value.min : range.min,
				typeof value.max === 'number' ? value.max : range.max
			]
		},
	},
	components: {
		VueSlider
	},
}
</script>