var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "details-can-afford panel" }, [
      _c("h2", { staticClass: "details-can-afford-title" }, [
        _vm._v("Can I afford it?"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "details-can-afford-body" }, [
        _c("div", { staticClass: "details-can-afford-body-image" }, [
          _c("img", {
            attrs: {
              loading: "lazy",
              src: _vm.car.thumbnail
                ? _vm.car.thumbnail
                : ["U", "T"].includes(_vm.car.stock_number.split("")[0])
                ? "/static/images/tCComingSoon.webp"
                : "/static/images/hComingSoon.webp",
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "details-can-afford-body-calc" }, [
          _c(
            "div",
            { staticClass: "details-can-afford-body-calc-options form-row" },
            [
              _c("div", { staticClass: "form-group col-12 col-md-6" }, [
                _c("label", { attrs: { for: "credit-score-calc" } }, [
                  _vm._v("Credit Score"),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    staticClass: "selectpicker form-control",
                    attrs: {
                      name: "credit-score-calc",
                      id: "credit-score-calc",
                    },
                  },
                  [
                    _c("option", { attrs: { value: "Excellent" } }, [
                      _vm._v(
                        "\n                Excellent (800+ Fico® Score)\n              "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "option",
                      {
                        attrs: { value: "Very Good" },
                        on: {
                          click: function ($event) {
                            return _vm.calcFinance()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                Very Good (740-799 Fico® Score)\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Good" } }, [
                      _vm._v("Good (670-739 Fico® Score)"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Fair" } }, [
                      _vm._v("Fair (580-669 Fico® Score)"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Challenged" } }, [
                      _vm._v(
                        "\n                Challenged (< 580 Fico® Score)\n              "
                      ),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group col-12 col-sm-6 col-md-3" },
                [
                  _c("label", { attrs: { for: "down-payment-calc" } }, [
                    _vm._v("Down Payment"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.finance.downPayment,
                          expression: "finance.downPayment",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "number", id: "down-payment-calc" },
                      domProps: { value: _vm.finance.downPayment },
                      on: {
                        change: function ($event) {
                          return _vm.calcDownpaymentOnChange($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.finance,
                            "downPayment",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group col-12 col-sm-6 col-md-3 mb-3" },
                [
                  _c("label", { attrs: { for: "months-payment-calc" } }, [
                    _vm._v("Term"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.detailsPage.finance.numOfMonths,
                          expression: "detailsPage.finance.numOfMonths",
                        },
                      ],
                      staticClass: "selectpicker form-control",
                      attrs: { name: "months", id: "months-payment-calc" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.detailsPage.finance,
                              "numOfMonths",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.calcMonthlyOnChange($event)
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "36" } }, [
                        _vm._v("36 months"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "48" } }, [
                        _vm._v("48 months"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "60" } }, [
                        _vm._v("60 months"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "72", selected: "" } }, [
                        _vm._v("72 months"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "84" } }, [
                        _vm._v("84 months"),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "details-can-afford-body-calc-estimate bottom-divider pb-3 row",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "details-can-afford-body-calc-estimate-left col-12 col-md-6",
                },
                [
                  _c("div", { staticClass: "afford-lineItem" }, [
                    _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.car.year) +
                          "\n                " +
                          _vm._s(_vm.car.make) +
                          "\n                " +
                          _vm._s(_vm.car.model) +
                          "\n                " +
                          _vm._s(_vm.car.series) +
                          "\n              "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "$" +
                          _vm._s(_vm._f("formatPrices")(_vm.car.price)) +
                          "*"
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "afford-lineItem" }, [
                    _c("span", [_vm._v("Tax, title and fees (CA Est.)")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "+$" +
                          _vm._s(
                            _vm._f("formatPrices")(_vm.finance.taxTitleFees)
                          )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "afford-lineItem" }, [
                    _c("span", [_vm._v("Minus Down Payment")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "-$" +
                          _vm._s(
                            _vm._f("formatPrices")(_vm.finance.downPayment)
                          )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "afford-lineItem" }, [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("span", [
                      _c("b", [
                        _vm._v(
                          "$" +
                            _vm._s(
                              _vm._f("formatPrices")(_vm.finance.totalAmount)
                            )
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "details-can-afford-body-calc-estimate-right col-12 col-md-6",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "details-can-afford-body-calc-estimate-right-monthley-payments estimate-callout",
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "estimate-number mr-md-2 mr-0" },
                        [
                          _vm._v(
                            "\n                $" +
                              _vm._s(
                                _vm._f("formatPrices")(
                                  _vm.finance.monthlyPayment
                                )
                              ) +
                              "\n              "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "etimate-label" }, [
                        _vm._v("Monthly"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "details-can-afford-body-calc-estimate-right-interest-rate estimate-callout",
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "estimate-number mr-md-2 mr-0" },
                        [_vm._v(_vm._s(_vm.getAPR()) + "%")]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "etimate-label" }, [
                        _vm._v("APR"),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(3),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("$")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "afford-subheading" }, [
      _vm._v("\n          Your Estimated Terms\n          "),
      _c("sup", [_vm._v("**")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "uppercase" }, [
      _c("b", [_vm._v("Finance Amount")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "details-can-afford-body-calc-save-time pt-3 d-flex flex-column justify-content-between",
      },
      [
        _c("h3", { staticClass: "afford-subheading" }, [
          _vm._v("Save Time In-Store"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-3" }, [
          _c(
            "div",
            {
              staticClass: "linkHolster col-12 d-flex flex-column flex-sm-row",
            },
            [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary",
                  attrs: { href: "/get-pre-approved/" },
                },
                [_vm._v("Get Pre-qualified")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-tertiary ml-0 mt-3 ml-sm-3 mt-sm-0",
                  attrs: { href: "/apply-for-financing/" },
                },
                [_vm._v("Apply for Financing")]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "mb-0 mt-auto disclaimerText" }, [
          _vm._v(
            "\n            **Values displayed are estimates for illustrative purposes only\n            and do not constitute a request for specific credit terms or an\n            offer of credit. Pre-qualification application is to determine\n            estimated credit terms on the vehicle you select and is subject to\n            credit approval and availability. Tax, title, and tags vary by\n            state and will be calculated at the time of purchase. APRs and\n            terms used in estimates may not be applicable based on vehicle and\n            state of purchase.\n          "
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }