var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ais-instant-search",
    {
      staticClass: "col-4 d-flex flex-auto justify-content-end",
      attrs: {
        "index-name": _vm.indexName,
        "search-client": _vm.searchClient,
        "search-function": _vm.searchFunction,
      },
    },
    [
      _c(
        "div",
        {
          class: [
            _vm.smallSearchIsOpened ? "opened" : "closed",
            "menu-menu__item header-search-bar d-flex",
          ],
        },
        [
          _c("ais-autocomplete", {
            ref: "searchAuto",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ currentRefinement, indices, refine }) {
                  return [
                    _c(
                      "b-input-group",
                      {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "append",
                              fn: function () {
                                return [
                                  _c("b-icon", {
                                    staticClass: "header-search-enter",
                                    staticStyle: {
                                      "margin-left": "4px",
                                      "align-self": "flex-end",
                                      "justify-self": "center",
                                    },
                                    attrs: {
                                      icon: "search",
                                      "font-scale": "2",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.searchState(
                                          currentRefinement
                                        )
                                      },
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c("b-form-input", {
                          ref: "suggestInput",
                          attrs: {
                            type: "search",
                            value: currentRefinement,
                            placeholder: "Search for a vehicle...",
                            autocomplete: "off",
                          },
                          on: {
                            input: refine,
                            focusin: function ($event) {
                              return _vm.suggestState()
                            },
                            focusout: function ($event) {
                              return _vm.handleFocusOut()
                            },
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.searchState(currentRefinement)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    currentRefinement && _vm.suggestOpenable
                      ? _c("div", { staticClass: "autopanel" }, [
                          _c(
                            "div",
                            { staticClass: "hits" },
                            _vm._l(indices, function (index) {
                              return _c(
                                "ul",
                                { key: index.label, staticClass: "list-group" },
                                [
                                  _c("h4", [_vm._v("Results")]),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: index.hits.length == 0,
                                          expression: "index.hits.length == 0",
                                        },
                                      ],
                                      staticClass: "list-group-item",
                                    },
                                    [_c("em", [_vm._v("No matches...")])]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(index.hits, function (hit) {
                                    return _c(
                                      "li",
                                      {
                                        key: hit.objectID,
                                        staticClass: "list-group-item",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "stretched-link",
                                            attrs: { href: _vm.hitLink(hit) },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "suggestion-content",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "suggestion-thumbnail",
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: hit.photos
                                                          ? hit.photos.split(
                                                              "|"
                                                            )[0]
                                                          : ["U", "T"].includes(
                                                              hit.stock_number
                                                            )
                                                          ? "/static/images/tCComingSoon.webp"
                                                          : "/static/images/hComingSoon.webp",
                                                        alt: "clawson honda",
                                                        width: "100",
                                                        loading: "lazy",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "suggestion-details",
                                                  },
                                                  [
                                                    _c("strong", [
                                                      hit.new
                                                        ? _c("span", [
                                                            _vm._v("New"),
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(hit.year) +
                                                            " " +
                                                            _vm._s(hit.make) +
                                                            " " +
                                                            _vm._s(hit.model)
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      hit.style
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(hit.style)
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      hit.door_count
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                hit.door_count
                                                              ) + "D"
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      hit.body
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(hit.body)
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              )
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("b-icon", {
        staticClass: "header-search-sm",
        attrs: { icon: "search" },
        on: {
          click: function ($event) {
            return _vm.toggleSmallSearch()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }